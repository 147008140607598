import React, {useEffect, useState} from 'react'
import {Box, Button, Tab, Typography} from "@mui/material";

export default function Dashboard() {


    return (
        <Box sx={{ width: '100%', typography: 'body1' }}>
            {/*<TabContext value={'1'}>*/}
            {/*    <Box sx={{ borderBottom: 1, borderColor: 'divider', ml:1, mr:1 }}>*/}
            {/*        <TabList aria-label="Dashboard tab">*/}
            {/*            <Tab label="Головна" value="1" />*/}
            {/*            <Tab label="Керування" value="2" />*/}
            {/*        </TabList>*/}
            {/*    </Box>*/}
            {/*    <TabPanel value="1">*/}
            {/*        <Typography>Dashboard 1</Typography>*/}
            {/*    </TabPanel>*/}
            {/*    <TabPanel value="2">*/}
            {/*        <Typography>Dashboard 2</Typography>*/}
            {/*    </TabPanel>*/}
            {/*</TabContext>*/}
        </Box>
    );
}
