import * as React from 'react';
import { useEffect} from 'react';

import Box from '@mui/material/Box';
import CitySelect from "../cityes/CitySelect";
import Divider from "@mui/material/Divider";
import {
    Button, FormControl, FormHelperText, Input, InputAdornment,
    Paper, Switch, Table, TableBody, TableCell, tableCellClasses,
    TableContainer, TableHead, TableRow, Typography
} from "@mui/material";
import {useDriverControlController} from "../../app/controllers/useDriverControlController";
import {useAppDispatch, useAppSelector} from "../../app/hooks/appHooks";
import {IDriverControlSettings} from "../../app/model/drivers/IDriverControlSettings";
import {styled} from "@mui/material/styles";
import {setOkMessage} from "../../app/reducers/message/globalMessageSlice";
import {Controller, SubmitHandler, useForm} from "react-hook-form";


export default function DriversSettings() {
    const {currentCity } = useAppSelector((state) => state.city.state)
    const [loading, setLoading] = React.useState(false)
    const {getCriteriaSettingsByCityId, updateCriteriaSettings} = useDriverControlController()
    const dispatch = useAppDispatch()
    const {
        register,
        handleSubmit,
        reset,
        control ,
        formState: { errors, defaultValues },
    } = useForm<IDriverControlSettings>({defaultValues:{
            cityId:0,
            isMinAcceptPercentAllowed:false,
            isAdvancedFiltersLock:false,
            minAcceptPercent:0
        }})


    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.primary.dark,
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
            borderLeft: '1px solid rgba(224, 224, 224, 1)',
        },
    }));

    useEffect(() => {
        getSettings(currentCity.id)
    },[])

    async function getSettings(cityId: number){
        setLoading(true)
        const settings = await getCriteriaSettingsByCityId(cityId)
        if(settings){
            reset(settings)
            setLoading(false)
        }
    }

    const onSubmit: SubmitHandler<IDriverControlSettings> = (data) => {
        setLoading(true)
        updateCriteriaSettings(data).then(value => {
                dispatch(setOkMessage({title:'Оновлення налаштувань збережені.', message:'Налаштування для міста ' + currentCity.name}))
                setLoading(false)
        })
    }

    const cityFilterHandler = (cityId: number) => {
        getSettings(cityId)
    }

    return (
        <Box sx={{width: '100%', height: '100%', mt: 2, display: 'flex', flexDirection: 'column', backgroundColor: 'grey.A100'}}>
            <Box sx={{display: 'flex', flexDirection: 'row'}}>
                <CitySelect onCityChange={cityFilterHandler} filters={undefined}/>
            </Box>
            <Divider variant="middle" sx={{mt: 2, mb: 2, ml: 8, mr: 8}}/>
            <form onSubmit={handleSubmit(onSubmit)} noValidate>
                <Box sx={{display: 'flex', flexDirection: 'column', pl: '1%', pr: '1%', width:'100%', maxWidth: 800 }}>
                    <Typography sx={{ml: 4}}>
                        Накладання обмежень на водіїв
                    </Typography>
                    <TableContainer sx={{ maxWidth: 800 }} component={Paper}>
                        <Table sx={{ minWidth: 600, maxWidth: 800 }} size="small" aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>Назва</StyledTableCell>
                                    <StyledTableCell align="right">Налаштування</StyledTableCell>
                                    <StyledTableCell align="right">Активно</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow key={0} sx={{borderBottom: '3px solid rgba(224, 224, 224, 1)'}}>
                                    <StyledTableCell component="th" scope="row">Базова коммісія для міста</StyledTableCell>
                                    <StyledTableCell align="right">
                                        <FormControl variant="standard" sx={{m: 1, width: '13ch'}}>
                                            <Input
                                                type="number" size={'small'} defaultValue={''} inputProps={{step: "1", 'aria-label': 'percent-acception'}}
                                                {...register("basicCommission", { required: 'Потрібно ввести процент', min:0 })}
                                                error={!!errors.basicCommission}
                                                id="standard-adornment-basicCommission"
                                                endAdornment={<InputAdornment position="end">%</InputAdornment>}
                                                aria-describedby="standard-basicCommission-helper-text"
                                            />
                                            <FormHelperText id="standard-basicCommission-helper-text">
                                                {errors.basicCommission?.message ? errors.basicCommission?.message : 'комісія' }
                                            </FormHelperText>
                                        </FormControl>
                                    </StyledTableCell>
                                    <StyledTableCell align="right" >
                                    </StyledTableCell>
                                </TableRow>
                                <TableRow key={1} sx={{borderBottom: '3px solid rgba(224, 224, 224, 1)'}}>
                                    <StyledTableCell component="th" scope="row">Блокування розширенних фільтрів для всіх
                                        водіїв в місті</StyledTableCell>
                                    <StyledTableCell align="right">
                                        <Controller
                                            name="isAdvancedFiltersLockAllowed"
                                            control={control}
                                            defaultValue={false}
                                            render={({ field }) => (
                                                <Switch
                                                    {...field}
                                                    checked={field.value}
                                                    onChange={(e) => field.onChange(e.target.checked)}
                                                />
                                            )}
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell align="right" >
                                        <Controller
                                            name="isAdvancedFiltersLock"
                                            control={control}
                                            defaultValue={false}
                                            render={({ field }) => (
                                                <Switch
                                                    {...field}
                                                    checked={field.value}
                                                    onChange={(e) => field.onChange(e.target.checked)}
                                                />
                                            )}
                                        />
                                    </StyledTableCell>
                                </TableRow>
                                <TableRow key={2} sx={{borderBottom: '3px solid rgba(224, 224, 224, 1)'}}>
                                    <StyledTableCell component="th" scope="row">Блокування розширенних фільтрів за
                                        мінімальним процентом прийнятих платежів</StyledTableCell>
                                    <StyledTableCell align="right">
                                        <FormControl variant="standard" sx={{m: 1, width: '13ch'}}>
                                            <Input
                                                type="number" size={'small'} defaultValue={''} inputProps={{step: "1", 'aria-label': 'percent-acception'}}
                                                {...register("minAcceptPercent", { required: 'Потрібно ввести процент', min:0 })}
                                                error={!!errors.minAcceptPercent}
                                                id="standard-adornment-percent-acception"
                                                endAdornment={<InputAdornment position="end">%</InputAdornment>}
                                                aria-describedby="standard-percent-acception-helper-text"
                                            />
                                            <FormHelperText id="standard-percent-acception-helper-text">
                                                {errors.minAcceptPercent?.message ? errors.minAcceptPercent?.message : 'мин. процент' }
                                            </FormHelperText>
                                        </FormControl>
                                    </StyledTableCell>
                                    <StyledTableCell align="right" >
                                        <Controller
                                            name="isMinAcceptPercentAllowed"
                                            control={control}
                                            defaultValue={false}
                                            render={({ field }) => (
                                                <Switch
                                                    {...field}
                                                    checked={field.value}
                                                    onChange={(e) => field.onChange(e.target.checked)}
                                                />
                                            )}
                                        />
                                    </StyledTableCell>
                                </TableRow>

                                <TableRow key={3} sx={{borderBottom: '3px solid rgba(224, 224, 224, 1)'}}>
                                    <StyledTableCell component="th" scope="row">Блокування розширенних фільтрів за
                                        мінімальним радіусом автозахоплення для всіх класів замовлення</StyledTableCell>
                                    <StyledTableCell align="right" >
                                        <FormControl variant="standard" sx={{m: 1, width: '13ch'}}>
                                            <Input
                                                type="number" size={'small'} defaultValue={''} inputProps={{step: "1", 'aria-label': 'percent-acception'}}
                                                {...register("minAutoCaptureRadiusNotEconom", { required: 'Потрібно ввести радіус', min:0 })}
                                                error={!!errors.minAutoCaptureRadiusNotEconom}
                                                id="standard-adornment-all-radius"
                                                endAdornment={<InputAdornment position="end">м.</InputAdornment>}
                                                aria-describedby="standard-all-radius-helper-text"
                                            />
                                            <FormHelperText id="standard-all-radius-helper-text">
                                                {errors.minAutoCaptureRadiusNotEconom?.message
                                                    ? errors.minAutoCaptureRadiusNotEconom?.message
                                                    : 'радіус (метри)'
                                                }
                                            </FormHelperText>
                                        </FormControl>
                                    </StyledTableCell>
                                    <StyledTableCell align="right"  >
                                        <Controller
                                            name="isMinAutoCaptureRadiusAllowed"
                                            control={control}
                                            defaultValue={false}
                                            render={({ field }) => (
                                                <Switch
                                                    {...field}
                                                    checked={field.value}
                                                    onChange={(e) => field.onChange(e.target.checked)}
                                                />
                                            )}
                                        />
                                    </StyledTableCell>
                                </TableRow>
                                <TableRow key={4} >
                                    <StyledTableCell component="th" scope="row">Розмір додаткової коміссії при активному фільтрі 'Тип оплати'</StyledTableCell>
                                    <StyledTableCell align="right" >
                                        <FormControl variant="standard" sx={{m: 1, width: '13ch'}}>
                                            <Input
                                                type="number" size={'small'} defaultValue={''} inputProps={{step: "1", 'aria-label': 'percent-acception'}}
                                                {...register("criterionPaymentTypeEnableSettings.paymentTypeFilterAdditionCommission", { required: 'Потрібно ввести комісію', min:0 })}
                                                error={!!errors.criterionPaymentTypeEnableSettings?.paymentTypeFilterAdditionCommission}
                                                id="standard-adornment-PaymentTypeEnable"
                                                endAdornment={<InputAdornment position="end">%</InputAdornment>}
                                                aria-describedby="standard-all-PaymentTypeEnable-helper-text"
                                            />
                                            <FormHelperText id="standard-all-PaymentTypeEnable-helper-text">
                                                {errors.minAutoCaptureRadiusNotEconom?.message
                                                    ? errors.minAutoCaptureRadiusNotEconom?.message
                                                    : 'комісія'
                                                }
                                            </FormHelperText>
                                        </FormControl>
                                    </StyledTableCell>
                                    <StyledTableCell align="right" sx={{borderBottom: '0px solid rgba(224, 224, 224, 1)'}} > </StyledTableCell>
                                </TableRow>
                                <TableRow key={5} >
                                    <StyledTableCell component="th" scope="row">Встановлення додаткової коміссії при активному фільтрі 'Тип оплати'</StyledTableCell>
                                    <StyledTableCell align="right" >
                                        <Controller
                                            name="criterionPaymentTypeEnableSettings.isPaymentTypeFilterWithCommissionValueRestrict"
                                            control={control}
                                            defaultValue={false}
                                            render={({ field }) => (
                                                <Switch
                                                    {...field}
                                                    checked={field.value}
                                                    onChange={(e) => field.onChange(e.target.checked)}
                                                />
                                            )}
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell align="right" sx={{borderBottom: '0px solid rgba(224, 224, 224, 1)'}}>
                                        <Controller
                                            name="criterionPaymentTypeEnableSettings.isPaymentTypeFilterAllowed"
                                            control={control}
                                            defaultValue={false}
                                            render={({ field }) => (
                                                <Switch
                                                    {...field}
                                                    checked={field.value}
                                                    onChange={(e) => field.onChange(e.target.checked)}
                                                />
                                            )}
                                        />
                                    </StyledTableCell>
                                </TableRow>
                                <TableRow key={6} sx={{borderBottom: '3px solid rgba(224, 224, 224, 1)'}}>
                                    <StyledTableCell component="th" scope="row">Блокування розширенних фільтрів при активному фільтрі 'Тип оплати'</StyledTableCell>
                                    <StyledTableCell align="right" >
                                        <Controller
                                            name="criterionPaymentTypeEnableSettings.isPaymentTypeFilterWithAdvancedFilterRestrict"
                                            control={control}
                                            defaultValue={false}
                                            render={({ field }) => (
                                                <Switch
                                                    {...field}
                                                    checked={field.value}
                                                    onChange={(e) => field.onChange(e.target.checked)}
                                                />
                                            )}
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell align="right" > </StyledTableCell>
                                </TableRow>

                                <TableRow key={7} >
                                    <StyledTableCell component="th" scope="row">Додаткова коміссія при відключенному автозахопленні</StyledTableCell>
                                    <StyledTableCell align="right" >
                                        <FormControl variant="standard" sx={{m: 1, width: '13ch'}}>
                                            <Input
                                                type="number" size={'small'} defaultValue={''} inputProps={{step: "1", 'aria-label': 'add-commission'}}
                                                {...register("autoCaptureDisabledSettings.additionCommissionForAutoCaptureDisabled",
                                                    { required: 'Потрібно ввести коміссію', min:0 })}
                                                error={!!errors.autoCaptureDisabledSettings?.additionCommissionForAutoCaptureDisabled}
                                                id="standard-adornment-all-commission"
                                                endAdornment={<InputAdornment position="end">%</InputAdornment>}
                                                aria-describedby="standard-all-commission-helper-text"
                                            />
                                            <FormHelperText id="standard-all-commission-helper-text">
                                                {errors.autoCaptureDisabledSettings?.additionCommissionForAutoCaptureDisabled?.message
                                                    ? errors.autoCaptureDisabledSettings.additionCommissionForAutoCaptureDisabled?.message
                                                    : 'коміссія '
                                                }
                                            </FormHelperText>
                                        </FormControl>
                                    </StyledTableCell>
                                    <StyledTableCell align="right"  >
                                        <Controller
                                            name="autoCaptureDisabledSettings.isAutoCaptureDisableAllowed"
                                            control={control}
                                            defaultValue={false}
                                            render={({ field }) => (
                                                <Switch
                                                    {...field}
                                                    checked={field.value}
                                                    onChange={(e) => field.onChange(e.target.checked)}
                                                />
                                            )}
                                        />
                                    </StyledTableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Button
                        sx={{width: '200px', alignSelf: 'end', mt: 2, mb:2}}
                        variant="contained"
                        disabled={defaultValues?.cityId === 0 || loading}
                        type="submit"
                    >Зберегти</Button>
                </Box>
            </form>
        </Box>
    );
}